import revive_payload_client_ELnXBMthlu from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.2.5_eslint@8.39.0_idb-keyval@6.2.1_typescript@5.0.4_vue-tsc@1.6.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import payload_client_3tXAcKk2Vd from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.2.5_eslint@8.39.0_idb-keyval@6.2.1_typescript@5.0.4_vue-tsc@1.6.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_TabEhP7dPi from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/@pinia+nuxt@0.4.8_typescript@5.0.4_vue@3.4.19/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/jflowerhome/devProjects/uwit/.nuxt/components.plugin.mjs";
import unhead_VXIxEIqfmh from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.2.5_eslint@8.39.0_idb-keyval@6.2.1_typescript@5.0.4_vue-tsc@1.6.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_wDiUAa9KXO from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/@nuxtjs+ionic@0.12.1/node_modules/@nuxtjs/ionic/dist/runtime/router.mjs";
import prefetch_client_Ks5QIXLCxG from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.2.5_eslint@8.39.0_idb-keyval@6.2.1_typescript@5.0.4_vue-tsc@1.6.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/Users/jflowerhome/devProjects/uwit/.nuxt/formkitPlugin.mjs";
import plugin_client_JIrIeYpQoN from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/nuxt-vuefire@0.1.6_@firebase+app-types@0.9.0_firebase@9.22.1_vuefire@3.1.0/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import templates_plugin_a596dcfe_4y3PnnXgTm from "/Users/jflowerhome/devProjects/uwit/.nuxt/templates.plugin.a596dcfe.ejs";
import composition_c51IFmiMzK from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.13_idb-keyval@6.2.1_vue@3.4.19/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_MwL7v8lSTD from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.13_idb-keyval@6.2.1_vue@3.4.19/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import ionic_gZEI5oQCXq from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/@nuxtjs+ionic@0.12.1/node_modules/@nuxtjs/ionic/dist/runtime/ionic.mjs";
import chunk_reload_client_AbWSnB5hY8 from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.2.5_eslint@8.39.0_idb-keyval@6.2.1_typescript@5.0.4_vue-tsc@1.6.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_ngHC71nzWg from "/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.1.1_@pinia+nuxt@0.4.8_pinia@2.1.7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _0_sentry_YD2SvzFSDA from "/Users/jflowerhome/devProjects/uwit/plugins/0.sentry.ts";
import _1_vue_query_client_H11EqkVrVK from "/Users/jflowerhome/devProjects/uwit/plugins/1.vue-query.client.ts";
import _2_firebase_client_Wutm3pNbia from "/Users/jflowerhome/devProjects/uwit/plugins/2.firebase.client.ts";
import _3_animoto_client_rwYDzkKsxF from "/Users/jflowerhome/devProjects/uwit/plugins/3.animoto.client.ts";
import _4_vue_social_sharing_client_vfW0f4FuCq from "/Users/jflowerhome/devProjects/uwit/plugins/4.vue-social-sharing.client.ts";
export default [
  revive_payload_client_ELnXBMthlu,
  payload_client_3tXAcKk2Vd,
  plugin_vue3_TabEhP7dPi,
  components_plugin_KR1HBZs4kY,
  unhead_VXIxEIqfmh,
  router_wDiUAa9KXO,
  prefetch_client_Ks5QIXLCxG,
  formkitPlugin_pZqjah0RUG,
  plugin_client_JIrIeYpQoN,
  templates_plugin_a596dcfe_4y3PnnXgTm,
  composition_c51IFmiMzK,
  i18n_MwL7v8lSTD,
  ionic_gZEI5oQCXq,
  chunk_reload_client_AbWSnB5hY8,
  plugin_ngHC71nzWg,
  _0_sentry_YD2SvzFSDA,
  _1_vue_query_client_H11EqkVrVK,
  _2_firebase_client_Wutm3pNbia,
  _3_animoto_client_rwYDzkKsxF,
  _4_vue_social_sharing_client_vfW0f4FuCq
]