import { default as complete_45profileDu5z0a7Ux8Meta } from "/Users/jflowerhome/devProjects/uwit/pages/auth/complete-profile.vue?macro=true";
import { default as create_45account2zCV9o3pk9Meta } from "/Users/jflowerhome/devProjects/uwit/pages/auth/create-account.vue?macro=true";
import { default as forgot_45passwordRJR8zgvbrrMeta } from "/Users/jflowerhome/devProjects/uwit/pages/auth/forgot-password.vue?macro=true";
import { default as manage_45accountJ7K1egQGGUMeta } from "/Users/jflowerhome/devProjects/uwit/pages/auth/manage-account.vue?macro=true";
import { default as save_45usernameRBpUgKyRrdMeta } from "/Users/jflowerhome/devProjects/uwit/pages/auth/save-username.vue?macro=true";
import { default as sign_45in_45emailGHOBaXpVy7Meta } from "/Users/jflowerhome/devProjects/uwit/pages/auth/sign-in-email.vue?macro=true";
import { default as sign_45in_45socialSX6ezxdv8eMeta } from "/Users/jflowerhome/devProjects/uwit/pages/auth/sign-in-social.vue?macro=true";
import { default as sign_45up_45userhGSpH08HniMeta } from "/Users/jflowerhome/devProjects/uwit/pages/auth/sign-up-user.vue?macro=true";
import { default as sign_45upqPEjXLL9NMMeta } from "/Users/jflowerhome/devProjects/uwit/pages/auth/sign-up.vue?macro=true";
import { default as daily_45witoRhdnuAyMKMeta } from "/Users/jflowerhome/devProjects/uwit/pages/daily-wit.vue?macro=true";
import { default as correct_45answerSnkS6nrYKCMeta } from "/Users/jflowerhome/devProjects/uwit/pages/game/correct-answer.vue?macro=true";
import { default as dashboardae0abPRZyMMeta } from "/Users/jflowerhome/devProjects/uwit/pages/game/dashboard.vue?macro=true";
import { default as descriptiondUJ0CCiqJSMeta } from "/Users/jflowerhome/devProjects/uwit/pages/game/description.vue?macro=true";
import { default as leaderboard4RY0BYO3d3Meta } from "/Users/jflowerhome/devProjects/uwit/pages/game/leaderboard.vue?macro=true";
import { default as questionklsyEsCbrzMeta } from "/Users/jflowerhome/devProjects/uwit/pages/game/question.vue?macro=true";
import { default as times_45upoFJ05LZ6OgMeta } from "/Users/jflowerhome/devProjects/uwit/pages/game/times-up.vue?macro=true";
import { default as uwititHI7dHYU7Meta } from "/Users/jflowerhome/devProjects/uwit/pages/game/uwit.vue?macro=true";
import { default as welcome_45backWJojNIEAUNMeta } from "/Users/jflowerhome/devProjects/uwit/pages/game/welcome-back.vue?macro=true";
import { default as wrong_45answerGmE4KCBQbaMeta } from "/Users/jflowerhome/devProjects/uwit/pages/game/wrong-answer.vue?macro=true";
import { default as indexV1U9Y0EDK6Meta } from "/Users/jflowerhome/devProjects/uwit/pages/index.vue?macro=true";
import { default as page28SzkU7BF50jMeta } from "/Users/jflowerhome/devProjects/uwit/pages/page28.vue?macro=true";
import { default as indexbzz7MUbdcqMeta } from "/Users/jflowerhome/devProjects/uwit/pages/sabbath/index.vue?macro=true";
import { default as user_45simulationSPupy3WHJDMeta } from "/Users/jflowerhome/devProjects/uwit/pages/user-simulation.vue?macro=true";
import { default as step2wMTRnJOzLYMeta } from "/Users/jflowerhome/devProjects/uwit/pages/welcome/step2.vue?macro=true";
import { default as step3RMPjcMzzGIMeta } from "/Users/jflowerhome/devProjects/uwit/pages/welcome/step3.vue?macro=true";
import { default as step4s3pB8TWWeJMeta } from "/Users/jflowerhome/devProjects/uwit/pages/welcome/step4.vue?macro=true";
import { default as step5FFtjNpw1U1Meta } from "/Users/jflowerhome/devProjects/uwit/pages/welcome/step5.vue?macro=true";
export default [
  {
    name: complete_45profileDu5z0a7Ux8Meta?.name ?? "auth-complete-profile___en",
    path: complete_45profileDu5z0a7Ux8Meta?.path ?? "/auth/complete-profile",
    meta: complete_45profileDu5z0a7Ux8Meta || {},
    alias: complete_45profileDu5z0a7Ux8Meta?.alias || [],
    redirect: complete_45profileDu5z0a7Ux8Meta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/auth/complete-profile.vue").then(m => m.default || m)
  },
  {
    name: create_45account2zCV9o3pk9Meta?.name ?? "auth-create-account___en",
    path: create_45account2zCV9o3pk9Meta?.path ?? "/auth/create-account",
    meta: create_45account2zCV9o3pk9Meta || {},
    alias: create_45account2zCV9o3pk9Meta?.alias || [],
    redirect: create_45account2zCV9o3pk9Meta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/auth/create-account.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordRJR8zgvbrrMeta?.name ?? "auth-forgot-password___en",
    path: forgot_45passwordRJR8zgvbrrMeta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordRJR8zgvbrrMeta || {},
    alias: forgot_45passwordRJR8zgvbrrMeta?.alias || [],
    redirect: forgot_45passwordRJR8zgvbrrMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: manage_45accountJ7K1egQGGUMeta?.name ?? "auth-manage-account___en",
    path: manage_45accountJ7K1egQGGUMeta?.path ?? "/auth/manage-account",
    meta: manage_45accountJ7K1egQGGUMeta || {},
    alias: manage_45accountJ7K1egQGGUMeta?.alias || [],
    redirect: manage_45accountJ7K1egQGGUMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/auth/manage-account.vue").then(m => m.default || m)
  },
  {
    name: save_45usernameRBpUgKyRrdMeta?.name ?? "auth-save-username___en",
    path: save_45usernameRBpUgKyRrdMeta?.path ?? "/auth/save-username",
    meta: save_45usernameRBpUgKyRrdMeta || {},
    alias: save_45usernameRBpUgKyRrdMeta?.alias || [],
    redirect: save_45usernameRBpUgKyRrdMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/auth/save-username.vue").then(m => m.default || m)
  },
  {
    name: sign_45in_45emailGHOBaXpVy7Meta?.name ?? "auth-sign-in-email___en",
    path: sign_45in_45emailGHOBaXpVy7Meta?.path ?? "/auth/sign-in-email",
    meta: sign_45in_45emailGHOBaXpVy7Meta || {},
    alias: sign_45in_45emailGHOBaXpVy7Meta?.alias || [],
    redirect: sign_45in_45emailGHOBaXpVy7Meta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/auth/sign-in-email.vue").then(m => m.default || m)
  },
  {
    name: sign_45in_45socialSX6ezxdv8eMeta?.name ?? "auth-sign-in-social___en",
    path: sign_45in_45socialSX6ezxdv8eMeta?.path ?? "/auth/sign-in-social",
    meta: sign_45in_45socialSX6ezxdv8eMeta || {},
    alias: sign_45in_45socialSX6ezxdv8eMeta?.alias || [],
    redirect: sign_45in_45socialSX6ezxdv8eMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/auth/sign-in-social.vue").then(m => m.default || m)
  },
  {
    name: sign_45up_45userhGSpH08HniMeta?.name ?? "auth-sign-up-user___en",
    path: sign_45up_45userhGSpH08HniMeta?.path ?? "/auth/sign-up-user",
    meta: sign_45up_45userhGSpH08HniMeta || {},
    alias: sign_45up_45userhGSpH08HniMeta?.alias || [],
    redirect: sign_45up_45userhGSpH08HniMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/auth/sign-up-user.vue").then(m => m.default || m)
  },
  {
    name: sign_45upqPEjXLL9NMMeta?.name ?? "auth-sign-up___en",
    path: sign_45upqPEjXLL9NMMeta?.path ?? "/auth/sign-up",
    meta: sign_45upqPEjXLL9NMMeta || {},
    alias: sign_45upqPEjXLL9NMMeta?.alias || [],
    redirect: sign_45upqPEjXLL9NMMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/auth/sign-up.vue").then(m => m.default || m)
  },
  {
    name: daily_45witoRhdnuAyMKMeta?.name ?? "daily-wit___en",
    path: daily_45witoRhdnuAyMKMeta?.path ?? "/daily-wit",
    meta: daily_45witoRhdnuAyMKMeta || {},
    alias: daily_45witoRhdnuAyMKMeta?.alias || [],
    redirect: daily_45witoRhdnuAyMKMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/daily-wit.vue").then(m => m.default || m)
  },
  {
    name: correct_45answerSnkS6nrYKCMeta?.name ?? "game-correct-answer___en",
    path: correct_45answerSnkS6nrYKCMeta?.path ?? "/game/correct-answer",
    meta: correct_45answerSnkS6nrYKCMeta || {},
    alias: correct_45answerSnkS6nrYKCMeta?.alias || [],
    redirect: correct_45answerSnkS6nrYKCMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/game/correct-answer.vue").then(m => m.default || m)
  },
  {
    name: dashboardae0abPRZyMMeta?.name ?? "game-dashboard___en",
    path: dashboardae0abPRZyMMeta?.path ?? "/game/dashboard",
    meta: dashboardae0abPRZyMMeta || {},
    alias: dashboardae0abPRZyMMeta?.alias || [],
    redirect: dashboardae0abPRZyMMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/game/dashboard.vue").then(m => m.default || m)
  },
  {
    name: descriptiondUJ0CCiqJSMeta?.name ?? "game-description___en",
    path: descriptiondUJ0CCiqJSMeta?.path ?? "/game/description",
    meta: descriptiondUJ0CCiqJSMeta || {},
    alias: descriptiondUJ0CCiqJSMeta?.alias || [],
    redirect: descriptiondUJ0CCiqJSMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/game/description.vue").then(m => m.default || m)
  },
  {
    name: leaderboard4RY0BYO3d3Meta?.name ?? "game-leaderboard___en",
    path: leaderboard4RY0BYO3d3Meta?.path ?? "/game/leaderboard",
    meta: leaderboard4RY0BYO3d3Meta || {},
    alias: leaderboard4RY0BYO3d3Meta?.alias || [],
    redirect: leaderboard4RY0BYO3d3Meta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/game/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: questionklsyEsCbrzMeta?.name ?? "game-question___en",
    path: questionklsyEsCbrzMeta?.path ?? "/game/question",
    meta: questionklsyEsCbrzMeta || {},
    alias: questionklsyEsCbrzMeta?.alias || [],
    redirect: questionklsyEsCbrzMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/game/question.vue").then(m => m.default || m)
  },
  {
    name: times_45upoFJ05LZ6OgMeta?.name ?? "game-times-up___en",
    path: times_45upoFJ05LZ6OgMeta?.path ?? "/game/times-up",
    meta: times_45upoFJ05LZ6OgMeta || {},
    alias: times_45upoFJ05LZ6OgMeta?.alias || [],
    redirect: times_45upoFJ05LZ6OgMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/game/times-up.vue").then(m => m.default || m)
  },
  {
    name: uwititHI7dHYU7Meta?.name ?? "game-uwit___en",
    path: uwititHI7dHYU7Meta?.path ?? "/game/uwit",
    meta: uwititHI7dHYU7Meta || {},
    alias: uwititHI7dHYU7Meta?.alias || [],
    redirect: uwititHI7dHYU7Meta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/game/uwit.vue").then(m => m.default || m)
  },
  {
    name: welcome_45backWJojNIEAUNMeta?.name ?? "game-welcome-back___en",
    path: welcome_45backWJojNIEAUNMeta?.path ?? "/game/welcome-back",
    meta: welcome_45backWJojNIEAUNMeta || {},
    alias: welcome_45backWJojNIEAUNMeta?.alias || [],
    redirect: welcome_45backWJojNIEAUNMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/game/welcome-back.vue").then(m => m.default || m)
  },
  {
    name: wrong_45answerGmE4KCBQbaMeta?.name ?? "game-wrong-answer___en",
    path: wrong_45answerGmE4KCBQbaMeta?.path ?? "/game/wrong-answer",
    meta: wrong_45answerGmE4KCBQbaMeta || {},
    alias: wrong_45answerGmE4KCBQbaMeta?.alias || [],
    redirect: wrong_45answerGmE4KCBQbaMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/game/wrong-answer.vue").then(m => m.default || m)
  },
  {
    name: indexV1U9Y0EDK6Meta?.name ?? "index___en",
    path: indexV1U9Y0EDK6Meta?.path ?? "/",
    meta: indexV1U9Y0EDK6Meta || {},
    alias: indexV1U9Y0EDK6Meta?.alias || [],
    redirect: indexV1U9Y0EDK6Meta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/index.vue").then(m => m.default || m)
  },
  {
    name: page28SzkU7BF50jMeta?.name ?? "page28___en",
    path: page28SzkU7BF50jMeta?.path ?? "/page28",
    meta: page28SzkU7BF50jMeta || {},
    alias: page28SzkU7BF50jMeta?.alias || [],
    redirect: page28SzkU7BF50jMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/page28.vue").then(m => m.default || m)
  },
  {
    name: indexbzz7MUbdcqMeta?.name ?? "sabbath___en",
    path: indexbzz7MUbdcqMeta?.path ?? "/sabbath",
    meta: indexbzz7MUbdcqMeta || {},
    alias: indexbzz7MUbdcqMeta?.alias || [],
    redirect: indexbzz7MUbdcqMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/sabbath/index.vue").then(m => m.default || m)
  },
  {
    name: user_45simulationSPupy3WHJDMeta?.name ?? "user-simulation___en",
    path: user_45simulationSPupy3WHJDMeta?.path ?? "/user-simulation",
    meta: user_45simulationSPupy3WHJDMeta || {},
    alias: user_45simulationSPupy3WHJDMeta?.alias || [],
    redirect: user_45simulationSPupy3WHJDMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/user-simulation.vue").then(m => m.default || m)
  },
  {
    name: step2wMTRnJOzLYMeta?.name ?? "welcome-step2___en",
    path: step2wMTRnJOzLYMeta?.path ?? "/welcome/step2",
    meta: step2wMTRnJOzLYMeta || {},
    alias: step2wMTRnJOzLYMeta?.alias || [],
    redirect: step2wMTRnJOzLYMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/welcome/step2.vue").then(m => m.default || m)
  },
  {
    name: step3RMPjcMzzGIMeta?.name ?? "welcome-step3___en",
    path: step3RMPjcMzzGIMeta?.path ?? "/welcome/step3",
    meta: step3RMPjcMzzGIMeta || {},
    alias: step3RMPjcMzzGIMeta?.alias || [],
    redirect: step3RMPjcMzzGIMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/welcome/step3.vue").then(m => m.default || m)
  },
  {
    name: step4s3pB8TWWeJMeta?.name ?? "welcome-step4___en",
    path: step4s3pB8TWWeJMeta?.path ?? "/welcome/step4",
    meta: step4s3pB8TWWeJMeta || {},
    alias: step4s3pB8TWWeJMeta?.alias || [],
    redirect: step4s3pB8TWWeJMeta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/welcome/step4.vue").then(m => m.default || m)
  },
  {
    name: step5FFtjNpw1U1Meta?.name ?? "welcome-step5___en",
    path: step5FFtjNpw1U1Meta?.path ?? "/welcome/step5",
    meta: step5FFtjNpw1U1Meta || {},
    alias: step5FFtjNpw1U1Meta?.alias || [],
    redirect: step5FFtjNpw1U1Meta?.redirect || undefined,
    component: () => import("/Users/jflowerhome/devProjects/uwit/pages/welcome/step5.vue").then(m => m.default || m)
  }
]