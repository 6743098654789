// Import necessary libraries and components
import { User, UwitUser } from '~/composables/user/Types';
import { useMutation } from '@tanstack/vue-query';
import { collection, doc, getDoc, getDocs, orderBy, query } from '@firebase/firestore';
import { where } from 'firebase/firestore';
import { Answer, Question } from '../game/GameTypes';

// Define the Firestore collection name for users
const collectionName = 'users';

// createUserQuery function creates a new user in the database
type UserNew = Omit<User, 'userId'>; // creates new type from User interface & omits userId so we can pass in User info without userId to create user for first time & avoid type errors

export const createUserQuery = () => {
  return useMutation({
    mutationFn: (user: UserNew) =>
      $fetch(`/users`, {
        method: 'POST',
        body: user,
        headers: {
          'Content-Type': 'application/json',
        },
        ...globalFetchOptions(),
        onResponseError(context: any): Promise<void> | void {
          throw new Error(context.response._data?.message);
        },
      }),
  });
};

// deleteUserQuery function deletes a user from the database
export const deleteUserQuery = () => {
  return useMutation({
    mutationFn: (userId: string) =>
      $fetch(`/users/${userId}`, {
        method: 'DELETE',
        ...globalFetchOptions(),
        onResponseError(context: any): Promise<void> | void {
          throw new Error(context.response._data?.message);
        },
      }),
  });
};

export const getUsers = async () => {
  const db = useFirestore();

  const userCollection = collection(db, 'users');
  const q = query(userCollection, orderBy('score', 'desc')).withConverter(firebaseConverter<UwitUser>());
  const userSnapshot = await getDocs(q);
  const userList = userSnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  return userList;
};

// check if the user is an admin by checking for the user.id in UserTesting collection
export const checkAdmin = async (userId: string) => {
  const db = useFirestore();
  const adminDocRef = doc(db, 'UserTesting', userId);
  const adminSnapshot = await getDoc(adminDocRef);
  return adminSnapshot.exists();
};

export const getUserQuestionsAndAnswers = async (
  userId: string,
  questions: Ref<Question[] | undefined>
): Promise<{ [key: string]: Answer }> => {
  if (!questions.value) return {};
  console.info('getting user questions and answers');
  const questionIds = questions.value.map((question) => question.id);
  const db = useFirestore();
  const userDocRef = doc(db, 'users', userId).withConverter(firebaseConverter<UwitUser>());
  const questionsCollectionRef = collection(userDocRef, 'questions');

  // Retrieve questions with specified IDs and their answers
  const questionData = [];
  for (const questionId of questionIds) {
    const questionDocRef = doc(questionsCollectionRef, questionId).withConverter(firebaseConverter<Question>());
    const questionSnapshot = await getDoc(questionDocRef);

    if (questionSnapshot.exists()) {
      const answersCollectionRef = collection(questionDocRef, 'answers');
      const orderedAnswersQuery = query(
        answersCollectionRef,
        where('last', '==', true),
        orderBy('date', 'desc')
      ).withConverter(firebaseConverter<Answer>());
      const answersSnapshot = await getDocs(orderedAnswersQuery);

      const answersData = answersSnapshot.docs.map((doc) => doc.data());

      questionData.push({
        id: questionId,
        answers: answersData,
      });
    }
  }
  const answersByQuestionId: { [key: string]: Answer } = questionData.reduce((acc, { id, answers }) => {
    acc[id] = answers[0];
    return acc;
  }, {} as { [key: string]: Answer });

  return answersByQuestionId;
};
