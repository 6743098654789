import { defineStore } from 'pinia';
import { doc, onSnapshot } from 'firebase/firestore';
import { getDoc } from '@firebase/firestore';
import { UwitUser } from '~/composables/user/Types';
import { checkAdmin } from './Queries';

export const useUserStore = defineStore(
  'user',
  () => {
    const uwitUser = ref<UwitUser | undefined>();
    // keep track of whether the user viewed create an account today
    const didViewCreateAccount = ref<string>('');
    let uwitUserSubscription: unknown;

    const setUwitUser = (user: UwitUser) => {
      uwitUser.value = user;
    };
    const removeUwitUser = () => {
      uwitUser.value = undefined;
    };
    const logout = async () => {
      removeUwitUser();
      didViewCreateAccount.value = '';
      if (uwitUserSubscription && typeof uwitUserSubscription === 'function') {
        uwitUserSubscription();
      }

      const authStore = useAuthStore();
      authStore.setIsAuthenticated(false);
      await navigateTo('/');
      localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE');
      setTimeout(() => {
        window.location.reload();
      }, 300);
    };
    const updateUserScore = (score: number) => {
      console.error('who updated the user score?');
      if (uwitUser.value) {
        // if the new score is lower than the current score, don't update
        if (score < uwitUser.value.score) {
          throw new Error('New score is lower than current score');
        }
        uwitUser.value.score = score;
      }
    };

    // get the user from the database, subscribe to changes, and return the user
    const getUwitUser = async (id: string): Promise<UwitUser | undefined> => {
      const db = useFirestore();
      const docRef = doc(db, 'users', id).withConverter(firebaseConverter<UwitUser>());
      const docSnap = await getDoc(docRef);

      // If the user data exists in the database, store it in the user store and return it
      if (docSnap.exists()) {
        setUwitUser(docSnap.data());
        subscribeToUwitUserChanges(); // subscribe to changes on the user
        const isAdmin = await checkAdmin(id);
        return { ...docSnap.data(), isAdmin };
      } else {
        return undefined;
      }
    };

    const subscribeToUwitUserChanges = async () => {
      // subscribe to changes to the user so that we can update their bank when they make a purchase or use a powerup
      const db = useFirestore();
      if (!uwitUserSubscription && uwitUser.value) {
        console.info('subscribing to uwit user changes');
        const isAdmin = await checkAdmin(uwitUser.value.userId);
        uwitUserSubscription = onSnapshot(
          doc(db, 'users', uwitUser.value.userId).withConverter(firebaseConverter<UwitUser>()),
          (doc) => {
            if (doc.exists()) {
              setUwitUser({ ...doc.data(), isAdmin });
            } else {
              logout();
              console.error('uwit user does not exist');
            }
          },
          (error) => console.error('error subscribing to uwit user changes', error)
        );
      }
    };
    const setDidViewCreateAccount = (didDecline: string) => {
      didViewCreateAccount.value = didDecline;
    };

    return {
      getUwitUser,
      uwitUser,
      updateUserScore,
      didViewCreateAccount,
      setDidViewCreateAccount,
      logout,
    };
  },
  { persist: true }
);
