import { UwitUser } from '~/composables/user/Types';
import { Capacitor } from '@capacitor/core';
import { connectFirestoreEmulator, getFirestore } from '@firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import {
  User,
  getAuth,
  getRedirectResult,
  indexedDBLocalPersistence,
  onAuthStateChanged,
  setPersistence,
} from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();
  const userStore = useUserStore();

  const remoteConfig = getRemoteConfig();
  remoteConfig.defaultConfig = {
    default_duration: 30,
  };

  fetchAndActivate(remoteConfig).catch((error) => console.error(error));

  if (config.public.appEnv === 'local') {
    await useEmulator();

    const db = getFirestore();
    connectFirestoreEmulator(db, 'localhost', 8089);

    const dbRealTime = getDatabase();
    connectDatabaseEmulator(dbRealTime, 'localhost', 9005);

    connectStorageEmulator(getStorage(), 'localhost', 9199);
  }

  // check if the current route is /auth or / and if so, redirect to /game/welcome-back
  // this is a bit fragile, but it works for now.  Might be able to move this logic somewhere else, but this is
  // the fastest since it is called as soon as the app has the authenticated user from firebase
  const checkForRedirectAuthenticatedUser = async (uwitUser?: UwitUser, authUser?: User) => {
    const route = useRouter();
    console.info('checking for redirect authenticated user', route.currentRoute.value.path);
    if (
      route.currentRoute.value.path === '/auth/sign-in-email' ||
      route.currentRoute.value.path === '/auth/sign-in-social' ||
      route.currentRoute.value.path === '/auth/complete-profile' ||
      route.currentRoute.value.path === '/auth/sign-up'
    ) {
      console.log('uwit user inside check for redirect auth', uwitUser);
      if (uwitUser && uwitUser.isGuest === false) {
        return navigateTo('/game/dashboard');
      } else if (uwitUser && uwitUser.isGuest === true && authUser) {
        // double check to make sure the user is stll a guest
        // this helps with when a user logs in as a guest, clicks back, and then logs in with their real account
        // need to replace the guest user with the authenticated user
        const updatedUwitUser = await userStore.getUwitUser(authUser.uid);
        if (updatedUwitUser?.isGuest === false) {
          return navigateTo('/game/dashboard');
        }
        return navigateTo('/auth/save-username');
      } else if (!uwitUser && authUser) {
        return navigateTo('/auth/save-username');
      }
    }
    if (route.currentRoute.value.path === '/') {
      console.info('redirecting to welcome back');
      return navigateTo('/game/welcome-back');
    }
  };

  const auth = getAuth();
  getRedirectResult(auth)
    .then(async (result) => {
      // console.log('redirect result', result);
      if (result?.user) {
        await userStore.getUwitUser(result.user.uid);
        authStore.setIsAuthenticated(true);
      }
    })
    .catch((error) => {
      console.error(error);
    });
  if (Capacitor.isNativePlatform() === true) {
    console.log('setting persistance to index since on native');
    await setPersistence(auth, indexedDBLocalPersistence);
  }

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // const userStore = useUserStore();
      console.log('User is signed in yay!', user.uid);
      const authStore = useAuthStore();
      const isAuthenticated = authStore.isAuthenticated;
      let uwitUser = userStore.uwitUser;
      if (isAuthenticated === false) {
        // if the user is not authenticated, get the user from the database and subscribe to changes
        // this helps avoid getting the user twice right after logging in or signing up
        uwitUser = await userStore.getUwitUser(user.uid);
      }
      authStore.setIsAuthenticated(true);

      checkForRedirectAuthenticatedUser(uwitUser, user);
    } else {
      console.log('User is signed out');
      // navigateTo('/auth/sign-in-social');
    }
  });
});
