
import { defuFn } from '/Users/jflowerhome/devProjects/uwit/node_modules/.pnpm/defu@6.1.3/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "firebaseConfig": {
    "apiKey": "AIzaSyCzmylZ08YYe2K1ypNial6mJxu7dOCNWg0",
    "authDomain": "play.uwit.app",
    "projectId": "uwd-prod",
    "storageBucket": "uwd-prod.appspot.com",
    "messagingSenderId": "640407064207",
    "appId": "1:640407064207:web:42765d15fb4ae5e6a2eafa",
    "measurementId": "G-MBWGD0Y4L1"
  },
  "vuefireOptions": {
    "optionsApiPlugin": false,
    "auth": false,
    "config": {
      "apiKey": "AIzaSyCzmylZ08YYe2K1ypNial6mJxu7dOCNWg0",
      "authDomain": "play.uwit.app",
      "projectId": "uwd-prod",
      "storageBucket": "uwd-prod.appspot.com",
      "messagingSenderId": "640407064207",
      "appId": "1:640407064207:web:42765d15fb4ae5e6a2eafa",
      "measurementId": "G-MBWGD0Y4L1"
    }
  }
}



export default /* #__PURE__ */ defuFn(inlineConfig)
