import { Answer, Question } from '~/composables/game/GameTypes';
import { useMutation } from '@tanstack/vue-query';
import { doc } from 'firebase/firestore';
import { collection, getDoc, getDocs } from '@firebase/firestore';
import { IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2';
import { PaymentIntent } from '@stripe/stripe-js';

export const todayQuestionQuery = async (): Promise<Question[]> => {
  const jwtToken = await getIdToken();
  const params = `?current_date=${getTodayDate()}`;

  const data = await $fetch<Question[]>(`/trivia/today-question${params}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`,
    },
    ...globalFetchOptions(),
  });

  // if (data.length > 0) {
  //   gameStore.setTodayQuestion(data);
  // }

  return data;
};

export const questionStartPlaying = async (id: string | undefined) => {
  if (typeof id === 'undefined') return {};
  const jwtToken = await getIdToken();

  return await $fetch(`/trivia/${id}/start`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`,
    },
    ...globalFetchOptions(),
  });
};

interface CheckAnswerResponse {
  answer: Answer;
}
export const checkCorrectAnswer = async (data: { id: string; value: string; current_date?: string }) => {
  const jwtToken = await getIdToken();
  const gameStore = useGameStore();

  if (gameStore.testingData.enable_testing_mode) {
    data = {
      ...data,
      current_date: gameStore.testingData.current_date,
    };
  }

  return await $fetch<CheckAnswerResponse>('/trivia/check-answer', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`,
    },
    ...globalFetchOptions(),
    body: data,
  });
};

export const questionEndPlaying = async (id: string) => {
  const jwtToken = await getIdToken();

  return await $fetch(`/trivia/${id}/end`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`,
    },
    ...globalFetchOptions(),
  });
};

export const checkTriviaQuestions = async () => {
  const jwtToken = await getIdToken();

  return await $fetch('/trivia/check-questions', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`,
    },
    ...globalFetchOptions(),
  });
};

export const takeTwo = () => {
  return useMutation(async (data: { questionId: string }) => {
    const jwtToken = await getIdToken();

    return await $fetch('/trivia/take-two', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      ...globalFetchOptions(),
      body: {
        id: data.questionId,
      },
    });
  });
};

export const addTime = () => {
  return useMutation(async (data: { questionId: string }) => {
    const jwtToken = await getIdToken();

    return await $fetch<{ success: string; data: number }>('/trivia/add-time', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      ...globalFetchOptions(),
      body: {
        id: data.questionId,
      },
    });
  });
};

export const retryGame = () => {
  return useMutation(async (data: { questionId: string }) => {
    const jwtToken = await getIdToken();

    return await $fetch<{ success: boolean }>('/trivia/retry-game', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      ...globalFetchOptions(),
      body: {
        id: data.questionId,
      },
    });
  });
};

export const addCoinsMutation = () => {
  return useMutation(
    async (payload: {
      productId: string;
      meta: IAPProduct | PaymentIntent;
      vendorId: string;
      vendor: keyof typeof TransactionVendorEnum;
    }) => {
      const jwtToken = await getIdToken();

      return await $fetch('/bank/add-coins/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}`,
        },
        ...globalFetchOptions(),
        body: payload,
      });
    }
  );
};

export const canPlayQuery = async () => {
  const uwitUser = useUserStore().uwitUser;
  if (uwitUser && uwitUser.isGuest) {
    const db = useFirestore();
    const userRef = doc(db, 'users', uwitUser.userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const questionsCollectionRef = collection(userRef, 'questions');
      const questionsQuerySnapshot = await getDocs(questionsCollectionRef);
      if (questionsQuerySnapshot.size >= 3) {
        return false;
      }
    } else {
      throw new Error('guest user does not exist');
      return true;
    }
  }
  return true;
};
