import { Answer } from 'composables/game/GameTypes';
import { PartialWithFieldValue, QueryDocumentSnapshot } from 'firebase/firestore';
import { getRemoteConfig, getString } from 'firebase/remote-config';

export function convertATags(htmlString: string) {
  // Parse the HTML
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlString, 'text/html');

  // Get all 'a' elements
  const aTags = htmlDoc.getElementsByTagName('a');

  // Convert NodeList to array to avoid live collection issue
  const aTagsArray = Array.prototype.slice.call(aTags);

  aTagsArray.forEach((aTag) => {
    // Create new button element
    const button = htmlDoc.createElement('span');
    button.textContent = aTag.textContent;
    button.setAttribute('onclick', `openBrowser('${aTag.href}')`);
    button.setAttribute('class', 'text-blue-500 underline cursor-pointer');

    // Replace 'a' with 'button'
    aTag.parentNode.replaceChild(button, aTag);
  });

  // Return the new HTML string
  return htmlDoc.body.innerHTML;
}

export function getTodayDate(): string {
  const gameStore = useGameStore();

  if (gameStore.testingData.enable_testing_mode) {
    return gameStore.testingData.current_date;
  }
  const date = new Date();
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are 0 based, so add 1 and pad with leading 0
  const day = ('0' + date.getDate()).slice(-2); // Pad with leading 0

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export const toastError = (title: string, message: string, timeout = 10000) => {
  const useErrorHandler = useErrorHandlerStore();
  useErrorHandler.addToast({
    type: ToastEnum.error,
    title,
    message,
    timeout,
  });
};

export const toastSuccess = (title: string, message: string, timeout = 10000) => {
  const useErrorHandler = useErrorHandlerStore();
  useErrorHandler.addToast({
    type: ToastEnum.success,
    title,
    message,
    timeout,
  });
};

export const toastWarning = (title: string, message: string, timeout = 10000) => {
  const useErrorHandler = useErrorHandlerStore();
  useErrorHandler.addToast({
    type: ToastEnum.warning,
    title,
    message,
    timeout,
  });
};

export const nl2br = (str: string | undefined) => {
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
};

export const preprocessMarkdown = (str: string | undefined) => {
  if (str !== undefined) {
    return str.replace(/(\*\*)\s?([^\*\*]+)\s?(\*\*)/g, function (_, start, content, end) {
      const leadingSpace = content.startsWith(' ') ? ' ' : '';
      const trailingSpace = content.endsWith(' ') ? ' ' : '';
      const cleanContent = content.trim();
      return `${leadingSpace}${start}${cleanContent}${end}${trailingSpace}`;
    });
  }
  return '';
};

export const checkForRedirectGuestUser = (answers: Answer[]) => {
  const userStore = useUserStore();

  const answersCount = Object.keys(answers).length;
  if (userStore.uwitUser?.isGuest && answersCount >= 3 && userStore.didViewCreateAccount !== getTodayDate()) {
    userStore.setDidViewCreateAccount(getTodayDate());
    useRouter().push('/auth/complete-profile');
  } else {
    useRouter().push('/game/dashboard');
  }
};

export const globalFetchOptions = () => {
  const config = useRuntimeConfig();
  return {
    baseURL: config.public.firebaseFunctionsUrl,
  };
};

// this is defined in vue-query.client.ts, configure it there rather than here
export const globalVueQueryOptions = () => {
  return {
    // keepPreviousData: true,
    // cacheTime: 0,
    // staleTime: 0,
    // retry: false,
  };
};

export const secondsToMMSS = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

export const shuffleArray = (array: any) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const getRemoteConfigValue = (option: string, defaultValue?: number) => {
  const remoteConfig = getRemoteConfig();
  return parseInt(getString(remoteConfig, option)) || defaultValue;
};

export const checkIfTodayIsSunday = (today: string) => {
  const date = new Date(today);
  return date.getUTCDay() === 0;
};

// converter from https://stackoverflow.com/questions/74456704/how-to-type-usefirestore-using-typescript-interfaces
export const firebaseConverter = <T>() => ({
  toFirestore: (data: PartialWithFieldValue<T>) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T,
});

export const formatNumber = (number: number) => {
  if (number > 0) return number.toLocaleString('en-US');

  return 0;
};

export const formatCurrency = (number: number) => {
  return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

export const padBankOrScore = (value: number | undefined) => {
  if (value !== undefined && value !== null) {
    return String(value).padStart(6, '0');
  }
  return '000000';
};
