import { VueQueryPlugin, VueQueryPluginOptions } from '@tanstack/vue-query';
import { persistQueryClient } from '@tanstack/query-persist-client-core';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

// rather than using the nuxt hydtration pattern for the query client, this persists the query client on the client side.  This performs well and avoids the complexity of the hydration pattern.

export default defineNuxtPlugin((nuxtApp) => {
  const onError = (error: any) => {
    if (error.status === 401) {
      const userStore = useUserStore();
      userStore.logout();

      const authStore = useAuthStore();
      authStore.setIsAuthenticated(false);

      useRouter().push('/welcome/step5');
    } else {
      let message = 'Please try again or contact support.';
      if (error && error.response && error.response._data && error.response._data.message) {
        message = error.response._data.message;
      } else if (error && error.message) {
        message = error.message;
      }
      toastError('Error', message, 5000);
      console.error(error);
    }
  };
  const vueQueryOptions: VueQueryPluginOptions = {
    queryClientConfig: {
      defaultOptions: {
        mutations: {
          onError,
        },
        queries: {
          cacheTime: 1000 * 60 * 30, // 30 minutes
          staleTime: 1000 * 60 * 2, // 2 minutes
          // cacheTime: 1000 * 3, // 3 seconds
          // staleTime: 1000 * 3,
          // cacheTime: 0,
          // staleTime: 0,
          refetchOnWindowFocus: false,
          onError,
        },
      },
    },
    clientPersister: (queryClient) => {
      return persistQueryClient({
        queryClient,
        persister: createSyncStoragePersister({ storage: localStorage }),
      });
    },
  };

  nuxtApp.vueApp.use(VueQueryPlugin, vueQueryOptions);
});
